
import React, { useState, useMemo } from 'react';

import { useConnectModal } from '@rainbow-me/rainbowkit'
import { Contract } from 'ethers';
import { useAccount, useWriteContract } from 'wagmi';
import { parseEther } from 'viem';

import { playSound, useEthersSigner } from '../util';

import {
    CONTRACT_ADDRESS as MINT_ADDRESS,
    CONTRACT_ABI as MINT_ABI
  } from '../mint-config';


const Mint = ({ CHAIN }) => {
  const { address, isConnected } = useAccount();
  const { openConnectModal } = useConnectModal();
  const [mintAmount, setMintAmount] = useState(1);
  const [referralCode, setReferralCode] = useState("");

  // const [callData, setCallData] = useState(null);
  const [mintError, setMintError] = useState(null);


  const signer = useEthersSigner({ chainId: CHAIN.id });

  // New way to write contracts in Wagmi v2
  const { writeContractAsync: publicMint } = useWriteContract();


  const [contract, errors] = useMemo(() => {
    try{
      if (signer) {
        const contract = new Contract(MINT_ADDRESS, MINT_ABI, signer);
        const errors = {};
        for (const f of contract.interface.fragments) {
          if (f.type === 'error') {
            errors[f.selector.toLowerCase()] = f.name;
          }
        }

        return [contract, errors];
      }
      else {
        return [null, null];
      }
    }
    catch(err) {
      console.warn({ err });
      return [null, null];
    }
  }, [signer]);


  // const estimate = useEstimateGas({ data: callData });
  // const contractSimulation = useSimulateContract({
  //   from: address,
  //   address: MINT_ADDRESS,
  //   abi: MINT_ABI,
  //   functionName: 'publicMint',
  //   args: [
  //     mintAmount,
  //     ""
  //   ],
  //   value: parseEther((0.021 * mintAmount).toString())
  // });

  // const generateCallData = async () => {
  //   try{
  //     const contract = new Contract(MINT_ADDRESS, MINT_ABI);
  //     const txn = await contract.publicMint.populateTransaction(mintAmount, "", {
  //       from: address,
  //       value: parseEther((0.021 * mintAmount).toString())
  //     });
  //     setCallData(txn.data);
  //   }
  //   catch(err) {
  //     console.warn({ err });
  //     setCallData(null);
  //   }
  // };

  // useEffect(() => {
  //   generateCallData();
  // }, [address, mintAmount]);


  const handleMint = async () => {
    playSound('/button-click.mp3', 0.15);
    if (!isConnected) {
      openConnectModal?.();
      return;
    }

    setMintError();
    // if (contractSimulation.status === 'pending') {
    //   const tmp = await contractSimulation.promise;
    //   console.log({ tmp });
    //   debugger;
    // }
    // else if (contractSimulation.status === 'error') {
    //   console.error(contractSimulation.error.shortMessage);
    //   setMintError(contractSimulation.error.shortMessage);
    //   return;
    // }
    // else if (contractSimulation.isFetching || contractSimulation.isLoading || contractSimulation.isPending || contractSimulation.isRefetching) {
    //   const tmp = await contractSimulation.promise;
    //   console.log({ tmp });
    //   debugger;
    // }


    // if (estimate.status === 'pending')
    //   await estimate.promise;

    // if (estimate.status === 'error') {
    //   console.error(estimate.error.shortMessage);
    //   setMintError(estimate.error.shortMessage);
    //   return;
    // }


    try {
      const ethValue = parseEther((0.021 * mintAmount).toString());
      const gasUnits = await contract.publicMint.estimateGas(mintAmount, referralCode, {
        from: address,
        value: ethValue
      });

      const hash = await publicMint({
        from: address,
        address: MINT_ADDRESS,
        abi: MINT_ABI,
        functionName: 'publicMint',
        args: [mintAmount, referralCode],
        gas: gasUnits,
        value: ethValue
      });
      console.log('Mint successful!', hash);
    } catch (err) {
      if (err.data) {
        const selector = err.data.substr(0, 10).toLowerCase();
        const errorName = errors[selector];

        if (errorName === 'EnforcedPause') {
            setMintError("Sales are currently paused");
        }
        else if (errorName === 'InsufficientBalance') {
            setMintError(`Your wallet does not have enough ETH to mint ${mintAmount} token(s)`);
        }
        else if (errorName === 'InvalidReferralCode') {
            setMintError(`Invalid referral code: ${referralCode}`);
        }
        else {
            setMintError(errorName);
        }
      }
      else if (err.shortMessage === "User rejected the request.") {
        // no-op
      }
      else {
        console.error('Mint error:', err);
        debugger;
        // setMintError();
      }
    }
  };

  return (
    <div className="space-y-8 font-['Press_Start_2P'] animate-fadeIn p-4">
      <h2 className="text-xl sm:text-2xl text-pink-400 animate-glitch hover:scale-105 transition-transform">{'>'} MINT_INFO</h2>

      {mintError && <p style={{ color: 'red' }}>{mintError}</p>}

      <div className="flex justify-center">
        <div className="relative w-64 sm:w-96 h-64 sm:h-96 perspective-1000">
          <div className="absolute inset-0 rounded-lg bg-gradient-to-r from-pink-500/20 via-pink-400/30 to-pink-500/20 animate-pulse" />
          <img
            src="mint.png"
            alt="Waifu NFT"
            className="absolute w-full h-full object-contain rounded-lg shadow-lg transform-style-3d animate-hover opacity-60"
            style={{
              filter: 'drop-shadow(0 0 15px #ff69b4) drop-shadow(0 0 25px #ff69b4)',
              transform: 'rotateY(0deg)',
              animation: 'hover 3s ease-in-out infinite',
              backfaceVisibility: 'visible'
            }}
          />
          <div className="absolute inset-0 rounded-lg border-2 border-pink-400/50 animate-pulse" />
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-pink-400/10 to-transparent animate-scan-vertical" />
        </div>
      </div>

      <div className="w-full space-y-4 mb-8">
        <input
              type="text"
              value={referralCode}
              onChange={(e) => setReferralCode(e.target.value || "")}
              placeholder="Enter Referral Code (optional)"
              className="w-full px-4 py-2 bg-pink-500/20 rounded-lg border-2 border-pink-400/50 text-pink-100 font-['Press_Start_2P'] text-sm placeholder-pink-300/50 focus:outline-none focus:border-pink-400/80"
            />
        </div>



      <style jsx>{`
        @keyframes hover {
          0%, 100% {
            transform: rotateY(-5deg);
          }
          50% {
            transform: rotateY(5deg);
          }
        }
        .animate-hover {
          animation: hover 3s ease-in-out infinite;
          transform-style: preserve-3d;
        }
      `}</style>

      <div className="flex flex-col sm:flex-row justify-center gap-4 mt-8">
        <div className="flex flex-col items-center gap-4">
          <div className="flex items-center gap-2">
            <button
              onClick={() => setMintAmount(Math.max(1, mintAmount - 1))}
              className="px-3 py-1 bg-pink-500/20 rounded-lg border-2 border-pink-400/50 text-pink-100"
            >
              -
            </button>
            <span className="text-pink-100 w-8 text-center">{mintAmount}</span>
            <button
              onClick={() => setMintAmount(Math.min(10, mintAmount + 1))}
              className="px-3 py-1 bg-pink-500/20 rounded-lg border-2 border-pink-400/50 text-pink-100"
            >
              +
            </button>
          </div>
          <button
            onClick={handleMint}
            className="w-full sm:w-auto px-8 py-4 bg-pink-500/20 rounded-lg transform hover:scale-110 transition-all duration-300 border-2 border-pink-400/50 shadow-[0_0_25px_rgba(236,72,153,0.6)] backdrop-blur-sm relative overflow-hidden before:absolute before:inset-0 before:bg-gradient-to-r before:from-transparent before:via-pink-400/20 before:to-transparent before:translate-x-[-200%] hover:before:translate-x-[200%] before:transition-transform before:duration-700 hover:border-pink-400/80 hover:shadow-[0_0_35px_rgba(236,72,153,0.7)] hover:bg-pink-500/30 pulse-animation"
          >
            <span className="relative z-10 text-pink-100 text-lg font-bold tracking-wider hover:text-white transition-colors">
              {isConnected ? 'MINT' : 'MINT'}
            </span>
          </button>
        </div>

        <div className="space-y-6 text-sm sm:text-base">
          <p className="font-['Press_Start_2P'] text-cyan-400 animate-pulse text-left mb-4"
             style={{
               textShadow: '0 0 10px #0ff, 0 0 20px #0ff'
             }}>
            MUST BE CONNECTED TO BASE NETWORK
          </p>
          <p className="terminal-text animate-typing text-pink-300 leading-relaxed">
            The Genesis Collection comprises 8888 Anime Waifu NFTs, each representing a unique and exclusive character within the WAIFUVERSE ecosystem. These NFTs grant holders access to a plethora of benefits, including community governance, utility access, and exclusive events.
          </p>

          <ul className="space-y-2 text-pink-300 list-disc pl-4">
            <li className="terminal-text">3333 for Public Mint at 0.021 ETH</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Mint;