import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { ExternalLink } from 'lucide-react';

import { RainbowKitProvider, getDefaultConfig } from '@rainbow-me/rainbowkit'
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

import { WagmiProvider } from 'wagmi';
import { base, baseSepolia } from 'wagmi/chains';

import Mint from './components/mint';
import Burn from './components/burn';
import Referral from './components/referral';
import NFTGallery from './components/NFTGallery';
import AlbumPlayer from './components/AlbumPlayer';

import { playSound } from './util';


import '@rainbow-me/rainbowkit/styles.css'

const CHAIN = base;



// function useMint() {
//   const { isConnected } = useAccount();
//   const { openConnectModal } = useConnectModal();
//   const [mintAmount, setMintAmount] = useState(1);

//   const { writeAsync: publicMint } = useWriteContract({
//     address: MINT_ADDRESS,
//     abi: MINT_ABI,
//     functionName: 'publicMint',
//   });

//   const handleMint = async () => {
//     playSound('/button-click.mp3', 0.15);
//     if (!isConnected) {
//       openConnectModal?.();
//       return;
//     }
//     try {
//       const hash = await publicMint({
//         args: [mintAmount, ""],
//         overrides: {
//           value: parseEther((0.021 * mintAmount).toString()),
//         },
//       });
//       console.log('Mint successful!', hash);
//     } catch (err) {
//       console.error('Mint error:', err);
//     }
//   };

//   return {
//     mintAmount,
//     setMintAmount,
//     handleMint,
//   };
// }

// function useBurn() {
//   const { isConnected } = useAccount();
//   const { openConnectModal } = useConnectModal();
//   const [burnAmount, setBurnAmount] = useState(1);

//   const { writeAsync: burn } = useWriteContract({
//     address: BURN_ADDRESS,
//     abi: BURN_ABI,
//     functionName: 'burn',
//   });

//   const handleBurn = async () => {
//     playSound('/button-click.mp3', 0.15);
//     if (!isConnected) {
//       openConnectModal?.();
//       return;
//     }
//     try {
//       const hash = await burn({
//         args: [burnAmount],
//       });
//       console.log('Burn successful!', hash);
//     } catch (err) {
//       console.error('Burn error:', err);
//     }
//   };

//   return {
//     burnAmount,
//     setBurnAmount,
//     handleBurn,
//   };
// }

// const config = createConfig({
//   chains: [base],
//   transports: {
//     [base.id]: http()
//   }
// });

const queryClient = new QueryClient();

const rainbowConfig = getDefaultConfig({
  appName: 'WaifuVerse',
  projectId: '242336b2b5817498381c743cafaaac0a',
  chains: [CHAIN],
});


// Detect mobile/tablet devices
const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

// Optimized Matrix Rain Effect with enhanced performance
const MatrixRain = React.memo(() => {
  const [columns, setColumns] = useState([]);
  const canvasRef = useRef(null);
  const animationFrameIdRef = useRef(null);
  const lastTimeRef = useRef(0);

  // Optimized color palette with reduced glow effects for mobile
  const colors = useMemo(() => [
    { color: '#ff00ff', glow: '0 0 10px #ff00ff' }, // Reduced glow
    { color: '#00ffff', glow: '0 0 10px #00ffff' },
    { color: '#ff1493', glow: '0 0 10px #ff1493' },
    { color: '#4169e1', glow: '0 0 10px #4169e1' },
    { color: '#9400d3', glow: '0 0 10px #9400d3' }
  ], []);

  // Optimized raindrop creation with mobile-friendly sizing
  const createRaindrops = useCallback(() => {
    const chars = 'ワイフフォローアニメデジタル無限夢来世界';
    // Adjust font size for mobile
    const baseFontSize = Math.min(window.innerWidth, window.innerHeight) / 40;
    // Reduce number of columns on mobile
    const isMobile = window.innerWidth < 768;
    const columnSpacing = isMobile ? 1.2 : 0.6;
    const columnCount = Math.ceil(window.innerWidth / (baseFontSize * columnSpacing));

    // Create fewer raindrops on mobile
    return Array.from({ length: isMobile ? columnCount/2 : columnCount }).map((_, index) => ({
      char: chars[Math.floor(Math.random() * chars.length)],
      x: (index * baseFontSize * columnSpacing),
      y: Math.random() * window.innerHeight * -1,
      z: Math.random() * 100, // Reduced z-depth for better performance
      opacity: Math.random() * 0.5 + 0.5,
      speed: Math.random() + 0.5, // Reduced speed range
      colorIndex: Math.floor(Math.random() * colors.length),
      fontSize: baseFontSize * (Math.random() * 0.2 + 0.9),
      rotationX: Math.random() * 30, // Reduced rotation
      rotationY: Math.random() * 30,
      rotationZ: Math.random() * 30,
    }));
  }, [colors]);

  useEffect(() => {
    if (isMobileDevice()) {
      return;
    }

    setColumns(createRaindrops());
    const chars = 'ワイフフォローアニメデジタル無限夢来世界';

    // Reduced FPS on mobile
    const isMobile = window.innerWidth < 768;
    const fps = isMobile ? 30 : 60;
    const interval = 1000 / fps;

    const animate = (currentTime) => {
      if (!animationFrameIdRef.current) return;

      if (currentTime - lastTimeRef.current >= interval) {
        lastTimeRef.current = currentTime;

        setColumns(prev => prev.map(drop => {
          const updates = {
            y: drop.y > window.innerHeight ? window.innerHeight * -0.2 : drop.y + drop.speed,
            z: drop.z > 100 ? 0 : drop.z + drop.speed / 8,
          };

          // Reduce random updates for better performance
          if (Math.random() < 0.01) {
            updates.char = chars[Math.floor(Math.random() * chars.length)];
            updates.opacity = Math.random() * 0.5 + 0.5;
            updates.colorIndex = Math.floor(Math.random() * colors.length);
          }

          if (Math.random() < 0.02) {
            updates.rotationX = (drop.rotationX + 0.5) % 30;
            updates.rotationY = (drop.rotationY + 0.5) % 30;
            updates.rotationZ = (drop.rotationZ + 0.5) % 30;
          }

          return { ...drop, ...updates };
        }));
      }

      animationFrameIdRef.current = requestAnimationFrame(animate);
    };

    animationFrameIdRef.current = requestAnimationFrame(animate);

    // Debounced resize handler
    let resizeTimeout;
    const handleResize = () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        setColumns(createRaindrops());
      }, 250);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    return () => {
      if (animationFrameIdRef.current) {
        cancelAnimationFrame(animationFrameIdRef.current);
        animationFrameIdRef.current = null;
      }
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, [createRaindrops, colors]);

  // Use transform3d for hardware acceleration
  return (
    <div className="fixed inset-0 pointer-events-none perspective-1000">
      {/* Add noise overlay */}
      <div className="noise" />

      {/* Add scan overlay */}
      <div className="overlay" />

      {columns.map((drop, i) => (
        <div
          key={i}
          className="absolute will-change-transform"
          style={{
            left: `${drop.x}px`,
            top: `${drop.y}px`,
            transform: `translate3d(0,0,${drop.z}px)
                       rotateX(${drop.rotationX}deg)
                       rotateY(${drop.rotationY}deg)
                       rotateZ(${drop.rotationZ}deg)`,
            opacity: drop.opacity * (1 - drop.z / 500),
            color: colors[drop.colorIndex].color,
            textShadow: colors[drop.colorIndex].glow,
            fontSize: `${drop.fontSize * (1 - drop.z / 1000)}px`,
            fontFamily: '"Inconsolata", "Press_Start_2P", monospace',
            filter: `blur(${Math.random() * 0.3}px)`, // Reduced blur
          }}
        >
          {drop.char}
        </div>
      ))}
      <canvas ref={canvasRef} className="absolute inset-0" />
    </div>
  );
});

// Enhanced Y2K Boot Screen Title with holographic effects
const BootTitle = React.memo(({ onComplete }) => {
  const [bootPhase, setBootPhase] = useState(0);
  const [glitchIntensity, setGlitchIntensity] = useState(0);
  const [showLinkStart, setShowLinkStart] = useState(false);
  const [opacity, setOpacity] = useState(1);

  // Set initial black background
  useEffect(() => {
    document.body.style.backgroundColor = 'black';
    return () => {
      document.body.style.backgroundColor = 'black';
    };
  }, []);

  useEffect(() => {
    // Play loading transition sound at max volume and wait for completion
    const audio = new Audio('/loading.mp3');
    audio.volume = 1.0;
    audio.play().catch(err => console.log('Audio play error:', err));

    const glitchInterval = setInterval(() => {
      setGlitchIntensity(Math.random());
    }, 100);

    const phases = [
      { delay: 1000, effect: 'scanlines' },
      { delay: 1500, effect: 'static' },
      { delay: 1500, effect: 'hologram' },
      { delay: 1000, showButton: true }
    ];

    let timeouts = [];

    phases.forEach((phase, index) => {
      const timeout = setTimeout(() => {
        setBootPhase(index);
        if (phase.showButton) {
          setShowLinkStart(true);
        }
      }, phases.slice(0, index + 1).reduce((acc, p) => acc + p.delay, 0));
      timeouts.push(timeout);
    });

    return () => {
      timeouts.forEach(t => clearTimeout(t));
      clearInterval(glitchInterval);
    };
  }, [onComplete]);

  const getGlitchStyle = useCallback(() => ({
    transform: glitchIntensity > 0.8 ? `translate(${Math.random() * 10 - 5}px, ${Math.random() * 10 - 5}px)` : 'none',
    filter: glitchIntensity > 0.8 ? `hue-rotate(${Math.random() * 360}deg)` : 'none',
  }), [glitchIntensity]);

  const handleLinkStart = () => {
    playSound('/loading-transition.mp3', 0.1);
    setOpacity(0);
    setTimeout(() => {
      document.body.style.backgroundColor = 'black';
      onComplete();
    }, 1000);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-100 z-50 transition-opacity duration-1000" style={{ opacity }}>
      <div className="relative text-center p-4 sm:p-8">
        <div className={`transition-all duration-500 ${bootPhase >= 0 ? 'opacity-100' : 'opacity-0'}`}>
          <div className="text-sm sm:text-base text-cyan-400 font-mono mb-4 animate-pulse">
            INITIALIZING SYSTEM...
          </div>
        </div>

        <div
          className={`transition-all duration-500 ${bootPhase >= 1 ? 'opacity-100 scale-100' : 'opacity-0 scale-95'}`}
          style={getGlitchStyle()}
        >
          <div
            className="text-2xl sm:text-4xl md:text-6xl font-['Press_Start_2P'] text-cyan-400 mb-8"
            style={{
              textShadow: '0 0 10px #0ff, 0 0 20px #0ff, 0 0 30px #0ff',
              letterSpacing: '0.1em',
              animation: 'hologram 3s infinite'
            }}
          >
            WAIFU<span className="text-pink-400">VERSE</span>
          </div>
        </div>

        <div className={`space-y-2 transition-all duration-500 mt-8 ${bootPhase >= 2 ? 'opacity-100' : 'opacity-0'}`}>
          <div className="text-xs sm:text-sm text-cyan-400 font-mono relative">
            {['LOADING MODULES...', 'CHECKING SYSTEM INTEGRITY...', 'INITIALIZING NEURAL NETWORK...', 'SYSTEM READY'].map((text, index) => (
              <div
                key={index}
                className="animate-typing"
                style={{
                  animationDelay: `${index * 300}ms`,
                  ...getGlitchStyle()
                }}
              >
                {text}
              </div>
            ))}
          </div>
        </div>

        {showLinkStart && (
          <button
            onClick={handleLinkStart}
            className="mt-8 px-8 py-4 bg-cyan-400/20 border-2 border-cyan-400 text-cyan-400 font-['Press_Start_2P']
                     hover:bg-cyan-400/30 transition-all duration-300 rounded-lg animate-pulse"
            style={{
              textShadow: '0 0 10px #0ff, 0 0 20px #0ff',
              boxShadow: '0 0 20px rgba(0, 255, 255, 0.3)'
            }}
          >
            LINK START
          </button>
        )}

        {/* Holographic overlay with reduced opacity */}
        {!isMobileDevice() && (
          <div className="absolute inset-0 pointer-events-none">
            <div className="absolute inset-0 bg-gradient-to-r from-transparent via-cyan-400/5 to-transparent animate-scan" />
            <div className="absolute inset-0 bg-gradient-to-b from-transparent via-cyan-400/3 to-transparent animate-scan-vertical" />
          </div>
        )}
      </div>
    </div>
  );
});

// Loading Screen with enhanced holographic and particle effects
const LoadingScreen = React.memo(({ onLoadingComplete }) => {
  const [opacity, setOpacity] = useState(1);
  const [progress, setProgress] = useState(0);
  const [loadingText, setLoadingText] = useState('Initializing Universe...');
  const [imageLoaded, setImageLoaded] = useState(false);
  const [particles, setParticles] = useState([]);

  // Set initial black background
  useEffect(() => {
    document.body.style.backgroundColor = 'black';
    return () => {
      document.body.style.backgroundColor = 'black';
    };
  }, []);

  const loadingPhrases = useMemo(() => [
    'Initializing Universe...',
    'Generating Waifus...',
    'Calibrating Reality...',
    'Synchronizing Dimensions...',
    'Almost Ready...'
  ], []);

  useEffect(() => {
    // Only generate particles on desktop
    if (!isMobileDevice()) {
      const particleCount = 50;
      const newParticles = Array.from({ length: particleCount }).map(() => ({
        x: Math.random() * window.innerWidth,
        y: Math.random() * window.innerHeight,
        size: Math.random() * 4 + 1,
        speedX: (Math.random() - 0.5) * 2,
        speedY: (Math.random() - 0.5) * 2,
        opacity: Math.random() * 0.5 + 0.3,
      }));
      setParticles(newParticles);
    }

    const progressInterval = setInterval(() => {
      setProgress(prev => {
        if (prev >= 100) {
          clearInterval(progressInterval);
          // Fade out transition
          setOpacity(0);
          // Complete loading after fade out
          setTimeout(() => {
            document.body.style.backgroundColor = 'black';
            onLoadingComplete();
          }, 1000);
          return 100;
        }
        return prev + 1;
      });
    }, 50);

    let phraseIndex = 0;
    const textInterval = setInterval(() => {
      phraseIndex = (phraseIndex + 1) % loadingPhrases.length;
      setLoadingText(loadingPhrases[phraseIndex]);
    }, 2000);

    let particleInterval;
    if (!isMobileDevice()) {
      particleInterval = setInterval(() => {
        setParticles(prev => prev.map(particle => ({
          ...particle,
          x: (particle.x + particle.speedX + window.innerWidth) % window.innerWidth,
          y: (particle.y + particle.speedY + window.innerHeight) % window.innerHeight,
          opacity: Math.sin(Date.now() / 1000) * 0.2 + 0.3,
        })));
      }, 16);
    }

    return () => {
      clearInterval(progressInterval);
      clearInterval(textInterval);
      if (particleInterval) {
        clearInterval(particleInterval);
      }
    };
  }, [onLoadingComplete, loadingPhrases]);

  return (
    <div
      className="fixed inset-0 bg-black z-50 transition-opacity duration-1000 ease-in-out"
      style={{ opacity }}
    >
      {/* Particle effect - only on desktop */}
      {!isMobileDevice() && particles.map((particle, index) => (
        <div
          key={index}
          className="absolute rounded-full bg-cyan-400"
          style={{
            left: particle.x,
            top: particle.y,
            width: particle.size,
            height: particle.size,
            opacity: particle.opacity,
            boxShadow: `0 0 ${particle.size * 2}px #0ff`,
            transition: 'opacity 0.3s',
          }}
        />
      ))}

      <div className="relative h-full flex items-center justify-center">
        <div className="relative w-[300px] h-[300px] sm:w-[400px] sm:h-[400px]">
          <img
            src="/loading.jpg"
            alt="Loading Hologram"
            onLoad={() => setImageLoaded(true)}
            className={`w-full h-full object-contain transition-opacity duration-1000 ${
              imageLoaded ? 'opacity-60' : 'opacity-0'
            }`}
            style={{
              filter: 'brightness(1.2) contrast(1.1) saturate(1.2) hue-rotate(-10deg)',
              mixBlendMode: 'screen',
              animation: 'float 6s infinite ease-in-out',
              transform: `perspective(1000px)
                         rotateX(${Math.sin(Date.now() / 1000) * 5}deg)
                         rotateY(${Math.cos(Date.now() / 1000) * 5}deg)`,
            }}
          />

          {/* Holographic effects - only on desktop */}
          {!isMobileDevice() && (
            <div className="absolute inset-0">
              <div className="absolute inset-0 bg-gradient-radial from-cyan-500/20 via-transparent to-transparent mix-blend-overlay animate-pulse" />
              <div className="absolute inset-0 animate-scanline" />
              <div className="absolute inset-0 bg-gradient-to-b from-transparent via-cyan-400/10 to-transparent animate-scan-vertical" />
            </div>
          )}
        </div>

        <div className="absolute inset-x-0 bottom-0 p-4 sm:p-8">
          <div className="max-w-md mx-auto space-y-4 backdrop-blur-sm bg-black/30 p-4 rounded-lg border border-cyan-400/20">
            <div className="text-cyan-400 font-['Press_Start_2P'] text-sm sm:text-base animate-pulse">
              {loadingText}
            </div>

            <div className="relative w-full h-2 bg-cyan-400/20 rounded-full overflow-hidden">
              <div
                className="absolute h-full bg-cyan-400 transition-all duration-300 rounded-full"
                style={{
                  width: `${progress}%`,
                  boxShadow: '0 0 10px #0ff, 0 0 20px #0ff',
                  filter: 'brightness(1.2)',
                }}
              />
              <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/30 to-transparent animate-shine" />
            </div>

            <div className="text-right text-cyan-400 font-['Press_Start_2P'] text-xs">
              {progress}%
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});


// Content sections with enhanced styling, animations and interactions
const content = {
  introduction: (
    <div className="space-y-6 font-['Press_Start_2P'] text-cyan-400 animate-fadeIn p-4 relative overflow-hidden">
      <h2 className="text-xl sm:text-2xl animate-glitch relative z-10 hover:scale-105 transition-transform">{'>'} INTRODUCTION_DATA</h2>
      <div className="flex flex-col items-center mb-8 relative group">
        <img
          src="/logo.png"
          alt="Waifuverse Logo"
          className="w-24 sm:w-32 md:w-40 animate-glitch opacity-80 group-hover:opacity-100 transition-all duration-500 cursor-pointer"
          style={{
            filter: 'drop-shadow(0 0 15px #0ff) drop-shadow(0 0 25px #0ff)',
            animation: 'glitch 2s infinite, hologram 3s infinite, float 6s ease-in-out infinite'
          }}
          onClick={() => playSound('/button-click.mp3', 0.3)}
        />
        <div className="w-full h-px bg-cyan-400/30 my-4" />
        <img 
          src="/dance.gif"
          alt="Dancing Animation"
          className="w-48 sm:w-64 md:w-80 mt-4"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-cyan-500/0 via-cyan-500/10 to-cyan-500/0 opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
        <span className="mt-4 text-lg sm:text-xl font-bold animate-pulse text-pink-400"
              style={{
                textShadow: '0 0 10px #ff69b4, 0 0 20px #ff69b4',
                animation: 'bounce 2s infinite'
              }}>
          MINTING NOW ON BASE!
        </span>
      </div>
      <p className="leading-relaxed terminal-text animate-typing text-sm sm:text-base relative z-10 hover:text-cyan-300 transition-colors font-['Press_Start_2P']">
        WAIFUVERSE is a pioneering NFT project merging anime with blockchain technology.
      </p>
      <p className="leading-relaxed terminal-text animate-typing text-sm sm:text-base relative z-10 hover:text-cyan-300 transition-colors font-['Press_Start_2P']">
        Our genesis collection features 8888 unique Anime Waifu NFTs obtained through burning/minting.
      </p>
      <p className="leading-relaxed terminal-text animate-typing text-sm sm:text-base relative z-10 hover:text-cyan-300 transition-colors font-['Press_Start_2P']">
        Our mission is to establish a sustainable ecosystem generating continuous value for members through diverse utilities and benefits across many distinct phases.
      </p>
      <div className="w-full h-px bg-cyan-400/30 my-4" />
    </div>
  ),

  mint: <Mint CHAIN={CHAIN} />,
//  burn: <Burn CHAIN={CHAIN} />,
  referral: <Referral CHAIN={CHAIN} />,

  whitepaper: (
    <div className="space-y-6 font-['Press_Start_2P'] text-cyan-400 animate-fadeIn p-4 relative overflow-hidden">
      <h2 className="text-xl sm:text-2xl md:text-3xl animate-glitch relative z-10 hover:scale-105 transition-transform mb-6">{'>'} WHITEPAPER_DATA</h2>

      {/* Mobile View */}
      <div className="block sm:hidden">
        <a
          href="https://docs.google.com/document/d/e/2PACX-1vSm3lz_VfRtr7XMdjqHh9MSWE-kPJ1gLytttOhUhEX210T-G3IpG_ltrPa8wIQRDor0q9GkNOvgEtM-/pub"
          target="_blank"
          rel="noopener noreferrer"
          className="block w-full py-4 text-center rounded-lg border-2 border-cyan-400 hover:border-cyan-300
                     bg-black/90 hover:bg-black/70 transition-all duration-500"
          onClick={() => playSound('/button-click.mp3', 0.2)}
        >
          <span className="text-sm">View Whitepaper</span>
          <ExternalLink size={16} className="inline-block ml-2 animate-pulse" />
        </a>
      </div>

      {/* Desktop View */}
      <div className="hidden sm:block relative w-full overflow-hidden rounded-lg border-2 border-cyan-400
                      group hover:border-cyan-300 transition-colors duration-500 shadow-lg hover:shadow-cyan-400/20">
        <div className="relative pt-[75%] md:pt-[56.25%] w-full">
          <iframe
            src="https://docs.google.com/document/d/e/2PACX-1vSm3lz_VfRtr7XMdjqHh9MSWE-kPJ1gLytttOhUhEX210T-G3IpG_ltrPa8wIQRDor0q9GkNOvgEtM-/pub?embedded=true"
            className="absolute top-0 left-0 w-full h-full bg-black/90"
            style={{
              filter: 'drop-shadow(0 0 10px #0ff)',
            }}
            title="Waifuverse Whitepaper"
          />
        </div>
        <div className="absolute inset-0 pointer-events-none">
          <div className="absolute inset-0 bg-gradient-to-r from-cyan-500/0 via-cyan-500/10 to-cyan-500/0
                          opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-cyan-400/5 to-transparent animate-scan-vertical" />
        </div>
      </div>
    </div>
  ),

  roadmap: (
    <div className="roadmap space-y-8 font-['Press_Start_2P'] animate-fadeIn p-4">
      <h2 className="text-cyan-400 text-xl sm:text-2xl animate-glitch hover:scale-105 transition-transform">{'>'} ROADMAP_DATA</h2>

      {[
        {
          phase: 'PHASE_1: GENESIS',
          description: '8888 Anime Waifu NFTs + waifuverse.io development [🏰]',
          status: 'EXECUTING...',
          statusColor: 'text-yellow-400',
          animation: 'animate-pulse'
        },
        {
          phase: 'PHASE_2: OTAKU',
          description: '8888 MALE Otaku PFP NFTs [🎭]',
          status: 'PENDING',
          statusColor: 'text-gray-400',
          animation: 'animate-blink'
        },
        {
          phase: 'PHASE_3: TAMASHI AI',
          description: 'Tamashi, meaning "soul" in Japanese, involving AI-powered Waifu personalities [🤖]',
          status: 'PENDING',
          statusColor: 'text-gray-400',
          animation: 'animate-blink'
        },
        {
          phase: 'PHASE_4: FASHION',
          description: 'Japanese designer collaborations & sustainable merch [👗]',
          status: 'PENDING',
          statusColor: 'text-gray-400',
          animation: 'animate-blink'
        },
        {
          phase: 'PHASE_5: MEME CARDS',
          description: 'Web3 & anime culture meme trading cards [🎴]',
          status: 'PENDING',
          statusColor: 'text-gray-400',
          animation: 'animate-blink'
        }
      ].map((phase, index) => (
        <div
          key={index}
          className="group hover:scale-105 transition-all duration-500 p-4 rounded-lg bg-cyan-900/5 hover:bg-cyan-900/15"
          onClick={() => playSound('/button-click.mp3', 0.15)}
        >
          <h3 className="text-cyan-400 text-lg sm:text-xl group-hover:text-cyan-300">{'>'} {phase.phase}</h3>
          <p className="mt-2 terminal-text animate-typing text-sm sm:text-base group-hover:text-cyan-300">{phase.description}</p>
          <div className={`mt-1 flex items-center gap-2 ${phase.statusColor} ${phase.animation}`}>
            <span className="inline-block w-2 h-2 rounded-full bg-current"></span>
            <span className="text-sm sm:text-base">STATUS: {phase.status}</span>
          </div>
        </div>
      ))}

      <style jsx>{`
        @keyframes blink {
          0%, 100% { opacity: 0.3; }
          50% { opacity: 1; }
        }
        .animate-blink {
          animation: blink 2s ease-in-out infinite;
        }
      `}</style>
    </div>
  ),

  team: (
    <div className="space-y-8 font-['Press_Start_2P'] animate-fadeIn p-4 relative">
      <h2 className="text-xl sm:text-2xl text-cyan-400 animate-glitch hover:scale-105 transition-transform">{'>'} TEAM_DATA</h2>

      {[
        {
          role: "FOUNDER & TECH_VISIONARY",
          name: "ELIJAH MATTHEW MOSES",
          img: "/efficio.jpg",
          alt: "Efficio",
          bio: "Pioneer in blockchain since 2012, founder of Animus Regnum LLC and Ignis AI Labs, with expertise in AI development and trading."
        },
        {
          role: "FOUNDER & DIRECTOR",
          name: "BEN KHAMHAENG",
          img: "/ben.jpg",
          alt: "Ben",
          bio: "Business Development Manager at Agora Visuals, Web3 veteran, former PwC professional, currently specializing in experiential and luxury retail partnerships."
        }
      ].map((member, index) => (
        <div key={index} className="space-y-4 transform hover:scale-105 transition-all duration-500 group">
          <h3 className="text-lg sm:text-xl text-cyan-400 group-hover:text-cyan-300">{'>'} {member.role}</h3>
          <div className="flex flex-col sm:flex-row items-center gap-4 p-4 rounded-lg bg-cyan-900/10 group-hover:bg-cyan-900/20 transition-all">
            <div className="relative">
              <img
                src={member.img}
                alt={member.alt}
                className="w-32 h-32 sm:w-48 sm:h-48 object-cover rounded-lg opacity-80 group-hover:opacity-100 transition-all duration-500"
                style={{
                  filter: 'drop-shadow(0 0 15px #0ff) drop-shadow(0 0 25px #0ff)',
                  animation: 'hologram 3s infinite'
                }}
                onClick={() => playSound('/button-click.mp3', 0.2)}
              />
              <div className="absolute inset-0 bg-gradient-to-br from-cyan-500/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500 rounded-lg" />
            </div>
            <div className="flex-1">
              <h4 className="text-base sm:text-lg animate-pulse mt-2 sm:mt-0 group-hover:text-cyan-300">{member.name}</h4>
              <p className="leading-relaxed terminal-text animate-typing text-sm sm:text-base mt-2 group-hover:text-cyan-300">
                {member.bio}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  ),

  Gallery: <NFTGallery />,

  tiers: (
    <div className="space-y-8 font-['Press_Start_2P'] animate-fadeIn p-4">
      <h2 className="text-xl sm:text-2xl text-cyan-400 animate-glitch hover:scale-105 transition-transform">{'>'} TIERS_DATA</h2>

      {[
        {
          tier: "MORTAL TIER",
          amount: "4,444",
          percentage: "50%",
          emoji: "⚔️"
        },
        {
          tier: "MYTHIC TIER",
          amount: "2,222",
          percentage: "25%",
          emoji: "☀️"
        },
        {
          tier: "ASCENDED TIER",
          amount: "1,333",
          percentage: "15%",
          emoji: "👑"
        },
        {
          tier: "TITAN TIER",
          amount: "533",
          percentage: "6%",
          emoji: "🛡️"
        },
        {
          tier: "DIVINE TIER",
          amount: "267",
          percentage: "3%",
          emoji: "✨"
        },
        {
          tier: "COSMIC CREATOR TIER",
          amount: "76",
          percentage: "0.85%",
          emoji: "🌀"
        },
        {
          tier: "SOURCE EDITIONS (1/1)",
          amount: "13",
          percentage: "0.15%",
          emoji: "🌙"
        }
      ].map((tier, index) => (
        <div
          key={index}
          className="group hover:scale-105 transition-all duration-500 p-4 rounded-lg bg-cyan-900/5 hover:bg-cyan-900/15"
          onClick={() => playSound('/button-click.mp3', 0.15)}
        >
          <div className="flex items-center gap-4">
            <span className="text-2xl">{tier.emoji}</span>
            <div>
              <h3 className="text-cyan-400 text-lg sm:text-xl group-hover:text-cyan-300">{'>'} {tier.tier}</h3>
              <p className="mt-2 terminal-text animate-typing text-sm sm:text-base group-hover:text-cyan-300">
                {tier.amount} ({tier.percentage})
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  ),

  Benefits: (
    <div className="space-y-8 font-['Press_Start_2P'] text-cyan-400 animate-fadeIn p-4">
      <h2 className="text-xl sm:text-2xl animate-glitch hover:scale-105 transition-transform">{'>'} BENEFITS_DATA</h2>

      {[
        {
          title: "NAME_YOUR_WAIFU",
          content: "Will be granted the ability to name your waifus in a future update."
        },
        {
          title: "REGENERATE_ART",
          content: "If there's a legitimate issue with your waifus, you will be granted the ability to regenerate the art."
        },
        {
          title: "EVOLUTION",
          content: "All of our collections will continuously get better and better through constant updates in art / metadata based on community feedback."
        },
        {
          title: "IP_RIGHTS",
          content: "All intellectual property rights reserved by WAIFUVERSE and its affiliates."
        },
        {
          title: "EXCLUSIVE_WHITELISTS",
          content: "Exclusive whitelist spots for all future collections."
        },
        {
          title: "AIRDROPS",
          content: "Special holder-only NFT Airdrops."
        },
        {
          title: "COLLECTIBLES",
          content: "Special access to limited edition Waifuverse collectibles."
        },
        {
          title: "EVENTS_AND_PARTIES",
          content: "Gain VIP entry to our carefully curated calander of events, ranging from intimate gatherings to large-scale celebrations."
        }
      ].map((section, index) => (
        <div
          key={index}
          className="space-y-4 hover:scale-105 transition-all duration-500 p-4 rounded-lg bg-cyan-900/5 hover:bg-cyan-900/15 group"
          onClick={() => playSound('/button-click.mp3', 0.15)}
        >
          <h3 className="text-lg sm:text-xl group-hover:text-cyan-300">{'>'} {section.title}</h3>
          <p className="leading-relaxed terminal-text animate-typing text-sm sm:text-base group-hover:text-cyan-300">
            {section.content}
          </p>
        </div>
      ))}
    </div>
  ),
  community: (
    <div className="space-y-8 font-['Press_Start_2P'] text-cyan-400 animate-fadeIn p-4">
      <h2 className="text-xl sm:text-2xl animate-glitch hover:scale-105 transition-transform">{'>'} COMMUNITY_DATA</h2>

      <div className="relative inline-block overflow-hidden rounded-lg border-2 border-cyan-400 group hover:border-cyan-300 transition-colors duration-500 w-full max-w-[350px] mx-auto">
        <div className="bg-cyan-900/10 group-hover:bg-cyan-900/20 transition-all w-full">
          <iframe
            src="https://discord.com/widget?id=1232726915740078101&theme=dark"
            width="100%"
            height="500"
            allowTransparency="true"
            frameBorder="0"
            sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
            className="rounded-lg w-full"
            style={{
              filter: 'drop-shadow(0 0 10px #0ff)',
              maxWidth: '100%'
            }}
          />
        </div>
        <div className="absolute inset-0 pointer-events-none">
          <div className="absolute inset-0 bg-gradient-to-r from-cyan-500/0 via-cyan-500/10 to-cyan-500/0 opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-cyan-400/5 to-transparent animate-scan-vertical" />
        </div>
      </div>

      <div className="space-y-6">
        {[
          {
            href: "https://x.com/Waifuverse_io",
            text: "FOLLOW_TWITTER"
          },
        ].map((link, index) => (
          <a
            key={index}
            href={link.href}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => playSound('/button-click.mp3')}
            className="flex items-center space-x-2 px-4 sm:px-6 py-2 sm:py-3 border-2 border-cyan-400
                       hover:bg-cyan-400/10 hover:scale-105 transition-all duration-500 w-fit text-sm sm:text-base
                       group relative overflow-hidden rounded-lg"
          >
            <span className="relative z-10 group-hover:text-cyan-300">{'>'} {link.text}</span>
            <ExternalLink size={16} className="animate-pulse sm:w-5 sm:h-5 relative z-10 group-hover:text-cyan-300" />
            <div className="absolute inset-0 bg-gradient-to-r from-cyan-500/0 via-cyan-500/10 to-cyan-500/0 translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-1000" />
          </a>
        ))}
      </div>

      <div>
        <a
          href="https://fauux.neocities.org/Love"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => playSound('/button-click.mp3', 0.3)}
          className="block w-full max-w-[350px] mx-auto px-6 py-3 text-center border-2 border-red-500
                     text-red-500 hover:text-red-300 hover:border-red-300
                     hover:bg-red-500/10 hover:scale-105 transition-all duration-500
                     animate-glitch relative overflow-hidden rounded-lg group"
          style={{
            textShadow: '0 0 10px #f00, 0 0 20px #f00',
            filter: 'drop-shadow(0 0 15px #f00)'
          }}
        >
          <span className="relative z-10 font-['Press_Start_2P'] text-lg">DO NOT CLICK</span>
          <div className="absolute inset-0 bg-gradient-to-r from-red-500/0 via-red-500/10 to-red-500/0 translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-1000" />
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-red-400/5 to-transparent animate-scan-vertical" />
        </a>

        <div className="h-8"></div>
      </div>
    </div>
  ),
  music: <AlbumPlayer />,
};

// Enhanced loading spinner with matrix rain effect and performance optimizations
const LoadingSpinner = React.memo(({ progress }) => {
  const [rainDrops, setRainDrops] = useState([]);
  const matrixChars = 'ワイフユニバースABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

  useEffect(() => {
    const interval = setInterval(() => {
      setRainDrops(prev => {
        const newDrops = [...prev];
        if (newDrops.length < 20) { // Reduced number of drops
          newDrops.push({
            x: Math.random() * window.innerWidth,
            y: -20,
            speed: 0.3 + Math.random() * 1,
            char: matrixChars[Math.floor(Math.random() * matrixChars.length)],
            opacity: 0.1 + Math.random() * 0.2
          });
        }
        return newDrops.map(drop => ({
          ...drop,
          y: drop.y + drop.speed,
          char: Math.random() > 0.98 ? matrixChars[Math.floor(Math.random() * matrixChars.length)] : drop.char
        })).filter(drop => drop.y < window.innerHeight);
      });
    }, 60);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative flex flex-col justify-center items-center py-4 sm:py-8 space-y-4">
      {rainDrops.map((drop, i) => (
        <div
          key={i}
          className="absolute text-cyan-400 font-mono animate-pulse text-sm sm:text-base"
          style={{
            left: drop.x,
            top: drop.y,
            opacity: drop.opacity
          }}
        >
          {drop.char}
        </div>
      ))}
      <div className="animate-pulse text-xl sm:text-2xl font-['Press_Start_2P'] z-10">
        LOADING<span className="animate-bounce">...</span>
      </div>
      {progress && (
        <div className="w-36 sm:w-48 h-2 bg-cyan-900/50 rounded-full z-10">
          <div
            className="h-full bg-cyan-400 rounded-full transition-all duration-300"
            style={{ width: `${progress}%` }}
          />
        </div>
      )}
    </div>
  );
});

// Enhanced terminal-style title with performance optimizations
const GlitchTitle = React.memo(() => {
  useEffect(() => {
    const audio = new Audio('/background.mp3');
    audio.volume = 0.1; // Low volume
    audio.loop = true;
    audio.play();

    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);

  return (
    <div className="text-center mb-4 sm:mb-8 relative" onClick={() => playSound('/hover.mp3', 0.3)}>
      <h1
        className="text-xl sm:text-3xl md:text-6xl font-['Press_Start_2P'] text-cyan-400 animate-pulse"
        style={{
          textShadow: '0 0 10px #0ff, 0 0 20px #0ff, 0 0 30px #0ff',
          letterSpacing: '0.1em',
          wordBreak: 'break-word'
        }}
      >
        WAIFU<span className="text-pink-400">VERSE</span>
        <span className="block mt-2 text-lg sm:text-2xl hover:text-pink-400 transition-colors">
          ワイフユニバース
        </span>
      </h1>
    </div>
  );
});

// Main App component with WagmiConfig wrapper
const App = () => {
  const [activeSection, setActiveSection] = useState('introduction');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showIntro, setShowIntro] = useState(true);
  const [showLoadingScreen, setShowLoadingScreen] = useState(false);

  useEffect(() => {
    if (showIntro) {
      const introTimer = setTimeout(() => {
        setShowIntro(false);
        setShowLoadingScreen(true);
      }, 5000);

      const loadingTimer = setTimeout(() => {
        setShowLoadingScreen(false);
      }, 10000);

      return () => {
        clearTimeout(introTimer);
        clearTimeout(loadingTimer);
      };
    }
  }, [showIntro]);

  const handleLogout = useCallback(() => {
    playSound('/button-click.mp3');
    setIsLoggedIn(false);
    setShowIntro(true);
    setShowLoadingScreen(false);
  }, []);

  // Wrap all content with WagmiConfig
  const renderContent = () => {
    if (showIntro) {
      return (
        <div className="min-h-screen bg-black">
          <MatrixRain />
          <BootTitle onComplete={() => setShowIntro(false)} />
        </div>
      );
    }

    if (showLoadingScreen) {
      return <LoadingScreen onLoadingComplete={() => setShowLoadingScreen(false)} />;
    }

    return (
      <div className="min-h-screen bg-black text-cyan-400 p-4 sm:p-8">
        <MatrixRain />
        <GlitchTitle />
        <div className="max-w-4xl mx-auto">
          <div className="flex flex-wrap gap-2 sm:gap-4 mb-4 sm:mb-8">
            {Object.keys(content).map(section => (
              <button
                key={section}
                onClick={() => {
                  setActiveSection(section);
                  playSound('/button-click.mp3');
                }}
                className={`px-3 py-1 sm:px-4 sm:py-2 font-['Press_Start_2P'] border-2 text-sm sm:text-base
                           ${activeSection === section ? 'border-cyan-400 bg-cyan-400/20' : 'border-cyan-400/50'}
                           hover:bg-cyan-400/10 transition-all duration-300`}
              >
                {'>'} {section.toUpperCase()}
              </button>
            ))}
          </div>
          <div className="animate-fadeIn bg-black/30 backdrop-blur-sm p-4 rounded-lg">
            {content[activeSection]}
          </div>
          <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 z-50">
            <button
              onClick={handleLogout}
              className="px-3 py-1 sm:px-4 sm:py-2 font-['Press_Start_2P'] border-2 border-cyan-400
                       hover:bg-cyan-400/10 transition-all duration-300 cursor-pointer text-sm sm:text-base
                       bg-black/30 backdrop-blur-sm"
            >
              {'>'} LOGOUT
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <WagmiProvider config={rainbowConfig}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          {renderContent()}
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};

export default App;
export { content, LoadingSpinner, GlitchTitle, playSound };
