
import { useMemo } from 'react'

import { BrowserProvider, FallbackProvider, JsonRpcProvider, JsonRpcSigner } from 'ethers'
import { useClient, useConnectorClient } from 'wagmi'

function clientToProvider(client) {
  const { chain, transport } = client
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  }
  if (transport.type === 'fallback') {
    const providers = transport.transports.map(
      ({ value }) => new JsonRpcProvider(value?.url, network),
    )
    if (providers.length === 1) return providers[0]
    return new FallbackProvider(providers)
  }
  return new JsonRpcProvider(transport.url, network)
}

function clientToSigner(client) {
  const { account, chain, transport } = client
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  }
  const provider = new BrowserProvider(transport, network)
  const signer = new JsonRpcSigner(provider, account.address)
  return signer
}

/** Action to convert a viem Client to an ethers.js Provider. */
export function useEthersProvider({ chainId }) {
  const client = useClient({ chainId })
  return useMemo(() => (client ? clientToProvider(client) : undefined), [client])
}

/** Hook to convert a viem Wallet Client to an ethers.js Signer. */
export function useEthersSigner({ chainId }) {
  const { data: client } = useConnectorClient({ chainId })
  return useMemo(() => (client ? clientToSigner(client) : undefined), [client])
}

// Enhanced sound system with volume control, error handling and iOS compatibility
export const playSound = (src, volume = 0.5) => {
  try {
    // Create audio context for iOS compatibility
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    const audioCtx = new AudioContext();

    const audio = new Audio(src);
    audio.volume = volume;

    // Enable audio on iOS
    document.addEventListener('touchstart', () => {
      audioCtx.resume();
    }, { once: true });

    // Add fade in/out effects with optimized intervals
    audio.addEventListener('play', () => {
      let vol = 0;
      const fadeIn = setInterval(() => {
        if (vol < volume) {
          vol += 0.1;
          audio.volume = vol;
        } else {
          clearInterval(fadeIn);
        }
      }, 50);
    });

    // Handle errors gracefully
    audio.play().catch(err => console.log('Audio play error:', err));
  } catch (err) {
    console.log('Sound system error:', err);
  }
};
