
import React, { useEffect, useMemo, useState } from 'react';

import { useConnectModal } from '@rainbow-me/rainbowkit'
import { Contract } from 'ethers';
import { useAccount, useWriteContract } from 'wagmi';

import { playSound, useEthersSigner } from '../util';

import {
    CONTRACT_ADDRESS as MINT_ADDRESS,
    CONTRACT_ABI as MINT_ABI
  } from '../mint-config';


const Referral = ({ CHAIN }) => {
    const { address, isConnected } = useAccount();
    const { openConnectModal } = useConnectModal();
    const [mintError, setMintError] = useState(null);
    const [myReferralCode, setMyReferralCode] = useState(null);

    const signer = useEthersSigner({ chainId: CHAIN.id });

    // New way to write contracts in Wagmi v2
    const { writeContractAsync: mintReferralCode } = useWriteContract();

    const [contract, errors] = useMemo(() => {
        try{
        if (signer) {
            const contract = new Contract(MINT_ADDRESS, MINT_ABI, signer);
            const errors = {};
            for (const f of contract.interface.fragments) {
            if (f.type === 'error') {
                errors[f.selector.toLowerCase()] = f.name;
            }
            }

            return [contract, errors];
        }
        else {
            return [null, null];
        }
        }
        catch(err) {
        console.warn({ err });
        return [null, null];
        }
    }, [signer]);


    const handleReferral = async (evt) => {
        if (!isConnected) {
            openConnectModal?.();
            return;
        }
      
        setMintError();

        try {
            const gasUnits = await contract.mintReferralCode.estimateGas({
                from: address
            });

            const hash = await mintReferralCode({
                from: address,
                address: MINT_ADDRESS,
                abi: MINT_ABI,
                functionName: 'mintReferralCode',
                args: [],
                gas: gasUnits
            });
            console.log('Mint successful!', hash);
            loadReferralCode();
        } catch (err) {
            if (err.data) {
                const selector = err.data.substr(0, 10).toLowerCase();
                const errorName = errors[selector];

                if (errorName === 'EnforcedPause') {
                    setMintError("Sales are currently paused");
                }
                else {
                    // TODO: prettify
                    setMintError(errorName);
                }
            }
            else if (err.shortMessage === "User rejected the request.") {
                // no-op
            }
            else {
                console.error('Mint error:', err);
                debugger;
                // setMintError();
            }
        }
    };

    const loadReferralCode = async () => {
        try {
            const code = await contract.myReferralCode({
                from: address
            });
            setMyReferralCode(code);
        }
        catch(err) {
            setMyReferralCode("");
        }
    };

    useEffect(() => {
        if (address && contract) {
            loadReferralCode(address);
        }
    }, [address, contract]);

    return (
    <div className="space-y-8 font-['Press_Start_2P'] animate-fadeIn p-4">
      <h2 className="text-xl sm:text-2xl text-pink-400 animate-glitch hover:scale-105 transition-transform">{'>'} REFERRAL_PROGRAM</h2>

      <div className="space-y-6">
        <h3 className="text-lg sm:text-xl text-pink-400">{'>'} ERC-987 REFERRAL SYSTEM</h3>

        {mintError && <p style={{ color: 'red' }}>{mintError}</p>}

        {myReferralCode ? (
            <div className="flex justify-center mt-8">
                <span className="relative z-10 font-['Press_Start_2P'] tracking-wider text-pink-100 hover:text-white transition-colors">{myReferralCode}</span>
            </div>
        ) : (
            <div className="flex justify-center mt-8">
            <button
                className="w-full sm:w-auto px-8 py-4 bg-pink-500/20 rounded-lg transform hover:scale-110 transition-all duration-300 border-2 border-pink-400/50 shadow-[0_0_25px_rgba(236,72,153,0.6)] backdrop-blur-sm relative overflow-hidden before:absolute before:inset-0 before:bg-gradient-to-r before:from-transparent before:via-pink-400/20 before:to-transparent before:translate-x-[-200%] hover:before:translate-x-[200%] before:transition-transform before:duration-700 hover:border-pink-400/80 hover:shadow-[0_0_35px_rgba(236,72,153,0.7)] hover:bg-pink-500/30 pulse-animation"
                onClick={() => playSound('/button-click.mp3', 0.15)}
            >
                <span onClick={handleReferral} className="relative z-10 font-['Press_Start_2P'] tracking-wider text-pink-100 hover:text-white transition-colors">REFERRAL CODE</span>
            </button>
            </div>
        )}

        <div
          className="group hover:scale-105 transition-all duration-500 p-4 rounded-lg bg-pink-900/5 hover:bg-pink-900/15"
          onClick={() => playSound('/button-click.mp3', 0.15)}
        >
          <h3 className="text-pink-400 text-lg sm:text-xl group-hover:text-pink-300">{'>'} INVITE FRIENDS</h3>
          <p className="mt-2 terminal-text animate-typing text-sm sm:text-base group-hover:text-pink-300">
            EVERYONE IS ELIGIBLE! UNLIMITED REFERRALS! Share your referral code to earn 13% of the mint price!
          </p>
        </div>

        <div className="space-y-16"></div>

        <div className="space-y-6">
          <div className="w-full max-h-[70vh] overflow-y-auto scrollbar-thin scrollbar-thumb-pink-400 scrollbar-track-pink-900/20 pr-4">
            <h4 className="text-pink-400">{'>'} WHAT IS ERC-987?</h4>
            <ul className="space-y-2 text-pink-300 text-sm sm:text-base list-disc pl-4">
              <li className="terminal-text">Innovative Technology: ERC-987 is a new standard for smart contracts that incorporates a referral reward mechanism directly within the minting process. This means that when a new NFT is minted using a referral code, the referrer instantly receives a reward, all automated through the smart contract.</li>
              <li className="terminal-text">Instant Referral Rewards: For each successful referral resulting in an NFT mint, the referring holder earns a 13% commission, automatically paid out to their wallet.</li>
            </ul>

            <h4 className="text-pink-400 mt-6">{'>'} HOW IT WORKS</h4>
            <ul className="space-y-2 text-pink-300 text-sm sm:text-base list-disc pl-4">
              <li className="terminal-text">Obtaining and Activating a Referral Code: Eligible participants can obtain and activate their unique referral code from their personal dashboard on our site.</li>
              <li className="terminal-text">Automatic Reward Distribution: Once the NFT is minted with a referral code, the smart contract automatically distributes the 13% reward to the referrer's wallet.</li>
            </ul>
          </div>

          <div className="w-full max-h-[70vh] overflow-y-auto scrollbar-thin scrollbar-thumb-pink-400 scrollbar-track-pink-900/20 pr-4">
            <h4 className="text-pink-400 text-lg mb-4">{'>'} REFERRAL SYSTEM RULES</h4>

            <div className="space-y-6 text-pink-300 text-sm">
              <div>
                <h5 className="font-bold mb-2">Using Referral Codes</h5>
                <p className="terminal-text">Obtain and Activate a Referral Code: Everyone is eligible to recieve a unique referral code, which they must first activate by minting it themselves from the referral page. This initial activation is necessary for the referral code to become functional.</p>
              </div>

              <div>
                <h5 className="font-bold mb-2">Sharing the Code</h5>
                <p className="terminal-text">Once activated, users may share their referral code with friends and acquaintances interested in minting Waifuverse NFTs.</p>
              </div>

              <div>
                <h5 className="font-bold mb-2">Code Usage by New Members</h5>
                <p className="terminal-text">Minters must enter the user's activated referral code when they mint an NFT. This ensures the referral is registered and tracked by our smart contract.</p>
              </div>

              <div>
                <h5 className="font-bold mb-2">No Retroactive Credits</h5>
                <p className="terminal-text">The referral code must be entered at the time of minting. If a new member fails to use the referral code during their mint, the referral will not be counted, and there will be no possibility to retroactively apply the referral.</p>
              </div>

              <div>
                <h5 className="font-bold mb-2">Automatic Payouts</h5>
                <p className="terminal-text">Referral earnings will be automatically paid out to the wallet that initially activated and minted the referral code, directly from the smart contract.</p>
              </div>

              <div>
                <h5 className="font-bold mb-2">Unlimited Referrals</h5>
                <p className="terminal-text">There is no limit to the number of referrals a holder can make. Rewards are distributed on a first-come, first-served basis.</p>
              </div>

              <div>
                <h5 className="font-bold mb-2">Prohibition of Fraudulent Activities</h5>
                <p className="terminal-text">No Fake Referrals: Creating alternate accounts or engaging in any activity to generate fake referrals is strictly prohibited.</p>
                <p className="terminal-text mt-2">Penalties for Violation: Violators will be banned from accessing all products and services offered by Waifuverse and may face additional penalties.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Referral;
