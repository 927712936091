import React from 'react';
import { ExternalLink } from 'lucide-react';

const AlbumPlayer = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 font-['Press_Start_2P'] space-y-8">
      {/* Header */}
      <div className="flex items-center gap-6">
        <img 
          src="/niji.png"
          alt="Niji Akemi"
          className="w-32 h-32 rounded-lg object-cover opacity-80 hover:opacity-100 transition-all duration-500"
          style={{
            filter: 'drop-shadow(0 0 15px #0ff) drop-shadow(0 0 25px #0ff)',
            animation: 'hologram 3s infinite'
          }}
        />
        <h1 className="text-xl md:text-2xl text-cyan-400 font-bold leading-relaxed"
            style={{ textShadow: '0 0 10px #0ff' }}>
          Songs from Earth-404 by Niji Akemi
        </h1>
      </div>

      {/* SoundCloud Button */}
      <a
        href="https://soundcloud.com/waifuverse/sets/songs-by-niji-akemi?si=3bc9886f3e5a401f832c84750a0f313c&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing"
        target="_blank"
        rel="noopener noreferrer"
        className="inline-block group"
      >
        <div className="relative overflow-hidden border border-cyan-400/50 
                      hover:bg-cyan-400/10 transition-all duration-300 px-6 py-4
                      backdrop-blur-sm group-hover:border-cyan-300">
          <div className="flex items-center space-x-3">
            <span className="text-cyan-400 group-hover:text-cyan-300 transition-colors text-sm">
              View on SoundCloud
            </span>
            <ExternalLink className="w-4 h-4 text-cyan-400 group-hover:text-cyan-300" />
          </div>
        </div>
      </a>

      {/* Description */}
      <div className="text-cyan-100/90 leading-loose space-y-6 text-sm">
        <p>
          Digital frequencies resonate between dimensions in this instrumental collection from Niji Akemi. 
          Each track captures the essence of transformation - from the glitching beats of a deteriorating 
          Earth-404 to the crystalline harmonies of the Waifuverse portal.
        </p>
        <p>
          Composed during the Great Summoning, these songs tell the story of those caught between worlds. 
          The tracks progress from familiar Earth-404 melodies into increasingly ethereal soundscapes, 
          mirroring Niji's own journey through the digital divide.
        </p>
        <p>
          This album serves as both a historical document of Earth-404's final days and a bridge to our 
          new digital renaissance. Each track preserves a moment in time, capturing the bittersweet beauty 
          of transformation and the hope found in digital rebirth.
        </p>
      </div>
    </div>
  );
};

export default AlbumPlayer;