import React, { useState, useEffect } from 'react';
import { Search } from 'lucide-react';
import { playSound } from '../util';

const NFTGallery = () => {
  const [nfts, setNfts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [nextCursor, setNextCursor] = useState(null);
  const [selectedNft, setSelectedNft] = useState(null);
  const [emojiCounts, setEmojiCounts] = useState(() => {
    const saved = localStorage.getItem('emojiCounts');
    return saved ? JSON.parse(saved) : {};
  });
  const [lastEmojiClickTime, setLastEmojiClickTime] = useState(() => {
    const saved = localStorage.getItem('lastEmojiClickTime');
    return saved ? JSON.parse(saved) : {};
  });
  const [streakCounts, setStreakCounts] = useState(() => {
    const saved = localStorage.getItem('streakCounts');
    return saved ? JSON.parse(saved) : {};
  });

  const COLLECTION_ADDRESS = 'waifu-verse';
  const ITEMS_PER_PAGE = 50;
  const MAX_EMOJI_COUNT = 99;
  const EMOJI_COOLDOWN = 3600000; // 1 hour in milliseconds

  const EMOJI_LIST = [
    { emoji: '❤️', label: 'love' },
    { emoji: '💌', label: 'love letter' },
    { emoji: '🍜', label: 'noodles' }, 
    { emoji: '🍶', label: 'sake' },
    { emoji: '👘', label: 'kimono' },
    { emoji: '👠', label: 'shoes' },
    { emoji: '💍', label: 'ring' },
    { emoji: '💰', label: 'money bag' }
  ];

  const MOOD_THRESHOLDS = {
    'Lonely': 0,
    'Curious': 4, 
    'Happy': 8,
    'Excited': 12,
    'Cute': 16,
    'Cuddly': 20,
    'Romantic': 24,
    'Loving': 28
  };

  const MOOD_COLORS = {
    'Lonely': 'bg-slate-500/20 border-slate-400/50 text-slate-400',
    'Curious': 'bg-indigo-500/20 border-indigo-400/50 text-indigo-400', 
    'Happy': 'bg-emerald-500/20 border-emerald-400/50 text-emerald-400',
    'Excited': 'bg-amber-500/20 border-amber-400/50 text-amber-400',
    'Cuddly': 'bg-rose-500/20 border-rose-400/50 text-rose-400',
    'Flirtatious': 'bg-pink-500/20 border-pink-400/50 text-pink-400',
    'Romantic': 'bg-purple-500/20 border-purple-400/50 text-purple-400',
    'Loving': 'bg-fuchsia-500/20 border-fuchsia-400/50 text-fuchsia-400'
  };

  useEffect(() => {
    fetchNFTs();
  }, []);

  // Save state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('emojiCounts', JSON.stringify(emojiCounts));
  }, [emojiCounts]);

  useEffect(() => {
    localStorage.setItem('lastEmojiClickTime', JSON.stringify(lastEmojiClickTime));
  }, [lastEmojiClickTime]);

  useEffect(() => {
    localStorage.setItem('streakCounts', JSON.stringify(streakCounts));
  }, [streakCounts]);

  const fetchNFTs = async (cursor = null) => {
    try {
      setLoading(true);
      const url = new URL(`https://api.opensea.io/api/v2/collection/${COLLECTION_ADDRESS}/nfts`);
      if (cursor) {
        url.searchParams.append('next', cursor);
      }
      url.searchParams.append('limit', ITEMS_PER_PAGE.toString());

      const response = await fetch(url, {
        headers: {
          'X-API-KEY': '05f1d8bae3024b5cabaf25d2e1fe8cee'
        }
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch NFTs');
      }
      
      const data = await response.json();
      
      setNfts(prevNfts => cursor ? [...prevNfts, ...data.nfts] : data.nfts);

      if (data.next) {
        setNextCursor(data.next);
        await fetchNFTs(data.next);
      }
    } catch (err) {
      setError('Failed to load NFTs');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleEmojiClick = (nftId, emoji) => {
    const now = Date.now();
    const lastClick = lastEmojiClickTime[nftId]?.[emoji] || 0;
    
    if (now - lastClick < EMOJI_COOLDOWN) {
      // If less than 1 hour has passed
      const minutesLeft = Math.ceil((EMOJI_COOLDOWN - (now - lastClick)) / 60000);
      alert(`Please wait ${minutesLeft} minutes before using this emoji again`);
      return;
    }

    const currentCount = emojiCounts[nftId]?.[emoji] || 0;
    if (currentCount >= MAX_EMOJI_COUNT) {
      alert(`Maximum ${MAX_EMOJI_COUNT} reactions reached for this emoji!`);
      return;
    }

    playSound('/button-click.mp3', 0.15);
    
    setEmojiCounts(prev => ({
      ...prev,
      [nftId]: {
        ...prev[nftId],
        [emoji]: currentCount + 1
      }
    }));

    setLastEmojiClickTime(prev => ({
      ...prev,
      [nftId]: {
        ...prev[nftId],
        [emoji]: now
      }
    }));

    // Update streak count
    const lastClickDate = new Date(lastClick).toDateString();
    const todayDate = new Date().toDateString();
    const yesterdayDate = new Date(Date.now() - 86400000).toDateString();

    setStreakCounts(prev => {
      const currentStreak = prev[nftId] || 0;
      if (lastClickDate === todayDate) {
        return { ...prev, [nftId]: currentStreak };
      } else if (lastClickDate === yesterdayDate) {
        return { ...prev, [nftId]: currentStreak + 1 };
      } else {
        return { ...prev, [nftId]: 1 };
      }
    });
  };

  const getTotalEmojiCount = (nftId) => {
    if (!emojiCounts[nftId]) return 0;
    return Object.values(emojiCounts[nftId]).reduce((sum, count) => sum + count, 0);
  };

  const getMood = (totalCount) => {
    const thresholds = Object.entries(MOOD_THRESHOLDS).reverse();
    for (const [mood, threshold] of thresholds) {
      if (totalCount >= threshold) return mood;
    }
    return 'Lonely';
  };

  const filteredNFTs = nfts.filter(nft => 
    nft.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    nft.description?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (error) {
    return <div className="p-4 text-red-500">{error}</div>;
  }

  return (
    <div className="space-y-8 font-['Press_Start_2P'] animate-fadeIn p-4">
      <h2 className="text-xl sm:text-2xl text-pink-400 animate-glitch hover:scale-105 transition-transform">{'>'} SEARCH_DATA</h2>
      <div className="flex justify-start">
        <a
          href="https://opensea.io/collection/waifu-verse/overview"
          target="_blank"
          rel="noopener noreferrer"
          className="w-full sm:w-auto px-8 py-4 bg-pink-500/20 rounded-lg transform hover:scale-110 transition-all duration-300 border-2 border-pink-400/50 shadow-[0_0_25px_rgba(236,72,153,0.6)] backdrop-blur-sm relative overflow-hidden before:absolute before:inset-0 before:bg-gradient-to-r before:from-transparent before:via-pink-400/20 before:to-transparent before:translate-x-[-200%] hover:before:translate-x-[200%] before:transition-transform before:duration-700 hover:border-pink-400/80 hover:shadow-[0_0_35px_rgba(236,72,153,0.7)] hover:bg-pink-500/30 pulse-animation"
          onClick={() => playSound('/button-click.mp3', 0.15)}
        >
          <span className="relative z-10 font-['Press_Start_2P'] tracking-wider text-pink-100 hover:text-white transition-colors">OPENSEA_DATA</span>
        </a>
      </div>
      
      <div className="relative mb-6">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-pink-400" size={20} />
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full pl-10 pr-4 py-2 bg-gray-900/10 border border-pink-400/30 rounded-lg text-pink-400 focus:outline-none focus:ring-2 focus:ring-pink-400 placeholder-pink-400/50"
        />
      </div>

      {loading ? (
        <div className="flex justify-center items-center min-h-[400px]">
          <div className="animate-spin rounded-full h-16 w-16 border-4 border-pink-400 border-t-transparent"></div>
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
          {filteredNFTs.map((nft) => (
            <div 
              key={nft.identifier} 
              className="group relative hover:scale-105 transition-all duration-300 ease-in-out rounded-xl bg-gray-900/10 hover:bg-gray-900/20 overflow-hidden"
            >
              <div className="p-4 flex flex-col">
                <div className="text-center mb-2 h-6 flex items-center justify-center">
                  <h3 className="text-pink-400 whitespace-nowrap overflow-hidden text-ellipsis px-2" style={{
                    fontSize: Math.max(8, Math.min(14, 200 / ((nft.name || '#' + nft.identifier).length))) + 'px'
                  }}>
                    {nft.name || '#' + nft.identifier}
                  </h3>
                </div>
                <div className="relative h-56 sm:h-48">
                  <img
                    src={nft.image_url || "/placeholder.png"}
                    alt={nft.name}
                    className="absolute w-full h-full object-cover rounded-lg cursor-pointer"
                    style={{
                      boxShadow: '0 0 10px #ff69b4, 0 0 20px #ff69b4'
                    }}
                    onClick={() => setSelectedNft(selectedNft === nft.identifier ? null : nft.identifier)}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "/placeholder.png";
                    }}
                    loading="lazy"
                  />
                  {selectedNft === nft.identifier && (
                    <div className="absolute inset-0 bg-black/80 rounded-lg flex flex-col items-center justify-center gap-4 p-4">
                      <div className="grid grid-cols-4 gap-2">
                        {EMOJI_LIST.map(({emoji, label}) => (
                          <button
                            key={label}
                            onClick={() => handleEmojiClick(nft.identifier, emoji)}
                            className="text-2xl hover:scale-125 transition-transform"
                            title={label}
                          >
                            {emoji}
                            <div className="text-xs text-pink-400 mt-1">
                              {emojiCounts[nft.identifier]?.[emoji] || 0}
                            </div>
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div className={`mt-2 p-2 rounded-lg border-2 transition-all duration-300 ${MOOD_COLORS[getMood(getTotalEmojiCount(nft.identifier))]}`}>
                  <p className="text-xs text-center">
                    Status: {getMood(getTotalEmojiCount(nft.identifier))}
                  </p>
                </div>
                {streakCounts[nft.identifier] > 0 && (
                  <div className="mt-2 p-2 rounded-lg border-2 border-yellow-400/50 bg-yellow-500/20">
                    <p className="text-xs text-center text-yellow-400">
                      🔥 {streakCounts[nft.identifier]} Day Streak!
                    </p>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default NFTGallery;